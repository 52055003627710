import axios from "axios";
import { LOADING, GET_ITEMS, SET_DEFAULT_ITEMS, SET_ITEM } from "./types";

const itemsUrl = "";

/* ACTIVATE LOADING */
export const setLoadingTrue = () => ({
  type: LOADING
});

/* GET iTEMS */
export const getItems = () => dispatch => {
  dispatch(setLoadingTrue());
  return axios
    .get(itemsUrl)
    .then(res => {
      return dispatch({
        type: GET_ITEMS,
        payload: res.data
      });
    })
    .catch(err => {
      return dispatch({
        type: SET_DEFAULT_ITEMS
      });
    });
};

/* SET_ITEM */
export const setItem = item => dispatch => {
  return dispatch({
    type: SET_ITEM,
    payload: item
  });
};
