import React from "react";
import Section from "./Section";
import NavList from "./NavList";

const NavWrapper = () => {
  const lists = {
    list1: [
      "Amazon.com",
      "Your Lists",
      "Find a Gift",
      "Browsing History",
      "Returns",
      "Customer Service"
    ],
    list2: [
      "Your Orders",
      "Gift Cards & Registry",
      "Your Account",
      "Sell Products on Amazon",
      "Your Recommendations",
      "Help"
    ]
  };
  return (
    <Section
      style={{
        background: "#222f3e",
        paddingBottom: "25px"
      }}
    >
      <NavList list={lists.list1} />
      <NavList list={lists.list2} />
    </Section>
  );
};

export default NavWrapper;
