import React from "react";
import Bar from "./Bar";
import Cart from "./Cart";
import SearchBar from "./SearchBar";
import user_icon from "../../Assets/user_icon.svg";

class LogoBar extends React.Component {
  render() {
    return (
      <Bar>
        <div className="left" style={{ width: "25%" }}>
          <div className="menu-icon">
            <img src="/images/hamburger.png" alt="menu" width="20" />
          </div>
          <a className="logo" href="/">
            <img
              src="/images/logo.png"
              width="80"
              alt="Amazon"
              style={{
                display: "block",
              }}
            />
          </a>
        </div>
        <div className="right mobile-only">
          <div className="account">
            <div>
              <p>Sign In <span>{" >"}</span></p>
            </div>

            <div>
              <img src={user_icon} alt="user"  />
            </div>
          </div>
          <Cart />
        </div>

        <div className="middle dt-only" style={{ width: "45%" }}>
          <SearchBar mobile={false} />
        </div>

        <div
          className="right dt-only"
          style={{ width: "25%", justifyContent: "center" }}
        >
          <h2>Ad</h2>
        </div>
      </Bar>
    );
  }
}

export default LogoBar;
