import React from "react";
import { Router, Route, Switch, Link, NavLink } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AmazonPage from "../components/AmazonPage";
import SearchPage from "../components/SearchPage";
import ItemPage from "../components/ItemPage";
import NotFoundPage from "../components/NotFoundPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

export const history = createHistory();

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Header />
      <Switch>
        <PublicRoute path="/" component={AmazonPage} exact={true} />
        <PublicRoute path="/search/:term" component={SearchPage} />
        <PublicRoute path="/item" component={ItemPage} />
        <Route component={NotFoundPage} />
      </Switch>
      <Footer />
    </div>
  </Router>
);

export default AppRouter;
