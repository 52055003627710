import React, { Component } from "react";
import LogoBar from "./LogoBar";
import SearchBar from "./SearchBar";
import Navigation from "./Navigation";
import DeliverBar from "./DeliverBar";

export default class index extends Component {
  render() {
    return (
      <div>
        <header>
          <LogoBar />
          <div className="mobile-only">
            <SearchBar mobile={true} />
            <Navigation />
          </div>
          <DeliverBar />
        </header>
      </div>
    );
  }
}
