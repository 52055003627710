import React from "react";

const SingleItemHorizontalCard = ({ item, hiddenAfter, index }) => {
  const dtOnly = hiddenAfter && index && hiddenAfter <= index;
  return (
    <div
      className={dtOnly ? "dt-only" : ""}
      style={{
        display: "flex",
        paddingBottom: "10px"
      }}
    >
      <div
        className="image-holder"
        style={{ textAlign: "center", margin: "10px 10px 15px", width: "30%" }}
      >
        <img
          src={item.image}
          height="80"
          alt={item.title}
          style={{ maxWidth: "100%", maxHeight: "80px" }}
        />
      </div>
      <div>
        <h3>{item.description}</h3>
        <h3>
          <sup>$</sup>
          {item.price}
        </h3>
      </div>
    </div>
  );
};

export default SingleItemHorizontalCard;
