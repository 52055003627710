import React, { Component } from "react";
import SectionHeader from "./SectionHeader";
import SectionFooter from "./SectionFooter";
import Card from "../Card/Card";

export default class Section extends Component {
  render() {
    const content = this.props.section;
    const type = this.props.section.type;
    const items = this.props.section.items;

    return (
      <div className="body-section">
        <SectionHeader header={content.sectionHeader} />
        <div className="carousel-control left dt-only">
          <i className="fas fa-angle-left" />
        </div>
        <Card type={type} items={items} />
        <div className="carousel-control right dt-only">
          <i className="fas fa-angle-right" />
        </div>
        <SectionFooter />
      </div>
    );
  }
}
