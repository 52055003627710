import React from "react";
import Section from "./Section";

const TopOfPage = () => {
  return (
    <Section
      style={{
        justifyContent: "center",
        background: "#37475a"
      }}
    >
      <div
        style={{
          textAlign: "center"
        }}
      >
        <a href="#top" className="top_of_page">
          <i className="fas fa-caret-up" />
          <div className="heading">TOP OF PAGE</div>
        </a>
      </div>
    </Section>
  );
};

export default TopOfPage;
