import React from "react";
import Cart from "./Cart";

const DtAccountWrapper = () => {
  return (
    <div className="dt-account-wrapper">
      <div className="lang-wrapper">
        <p>
          <small>EN</small>
        </p>
        <div>
          <img src="/images/lang.png" width="20" alt="English" />
          <i className="fas fa-caret-down" />
        </div>
      </div>

      <ul>
        <li>
          <p>
            <small>Sign In</small>
          </p>
          <div>
            <p>
              <strong>Account & Lists</strong>
            </p>
            <i className="fas fa-caret-down" />
          </div>
        </li>
        <li>
          <div>
            <p>
              <strong>Orders</strong>
            </p>
            <i className="fas fa-caret-down" />
          </div>
        </li>
        <li>
          <div>
            <p>
              <strong>Prime</strong>
            </p>
            <i className="fas fa-caret-down" />
          </div>
        </li>
        <li>
          <div>
            <Cart mobile={false} />
            <p>
              <strong>Cart</strong>
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DtAccountWrapper;
