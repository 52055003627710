import React from "react";
import { history } from "../../routers/AppRouter";

const PlaceOrder = ({ item, onCtaClick }) => {
  const { price } = item;
  const estimatedTax = ((10 / 100) * parseInt(price)).toFixed(2);
  const totalPrice = parseFloat(price) + parseFloat(estimatedTax);

  const onFinalClick = () => {
    // Generate Cookie here then redirect to amazon
    localStorage.setItem("survey", "completed");
    window.location.replace(`${item?.web_scraper_start_url}`);
  };
  return (
    <div className="place-your-order">
      <div className="cta-holder">
        <button className="cta" onClick={onFinalClick}>
          Place your order
        </button>
      </div>
      <div className="invoice">
        <div className="section">
          <div className="card">
            <p>
              Shipping to: <strong>Trigg Watson, Burrage, 2901...</strong>
            </p>
          </div>
          <div className="card">
            <div className="row">
              <div className="left">Items:</div>
              <div className="right">${price}</div>
            </div>
            <div className="row">
              <div className="left blue">Shipping & handeling:</div>
              <div className="right">$00.00</div>
            </div>
            <div className="row">
              <div className="left">Total before tax:</div>
              <div className="right">${price}</div>
            </div>
            <div className="row">
              <div className="left">Estemated tax to be collected:</div>
              <div className="right">${estimatedTax}</div>
            </div>
            <div className="row last">
              <div className="left">
                <strong>Order total</strong>:
              </div>
              <div className="right red">
                <strong>${totalPrice}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="title">Shipping address</div>
      <div className="section">
        <div className="card row">
          <div className="text">
            <strong>Trigg Watson Burrage</strong>
            <br />
            <small>2901 Indiana Blvd. Apt 119, Dallas, TX 752...</small>
          </div>
          <div className="icon">
            <i className="fas fa-angle-right" />
          </div>
        </div>
        <div className="card row">
          <div className="text">
            <strong>Add delivery Instructions </strong>
            <small>(optional)</small>
          </div>
          <div className="icon">
            <i className="fas fa-angle-right" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlaceOrder;
