import React, { Component } from "react";

export default class Bar extends Component {
  render() {
    let style = this.props.style;
    let classes = this.props.classes ? this.props.classes + " bar" : "bar";
    return (
      <div className={classes} style={{ ...style }}>
        {this.props.children}
      </div>
    );
  }
}
