import React from "react";
import Bar from "./Bar";
import DtAccountWrapper from "./DtAccountWrapper";

const DeliverBar = () => {
  return (
    <Bar classes="delevery-bar">
      <div className="left">
        <div className="pin-image-container">
          <img src="/images/pin.png" width="20" height="22" alt="Deliver" />
        </div>
        <div>
        <p>Select a location to see product availablity</p>

        </div>
      </div>
      <div
        className=" dt-only"
        style={{
          display: "flex",
          width: "80%",
          justifyContent: "space-between"
        }}
      >
        <div className="left">
          <ul className="navigation-menu dt-nav">
            <li>Buy Again</li>
            <li>Your Pickup Location</li>
            <li className="lg-only">Browsing History</li>
            <li className="lg-only">Amazon.com</li>
            <li className="lg-only">Today's Deals</li>
          </ul>
        </div>
        <div className="right">
          <DtAccountWrapper />
        </div>
      </div>
    </Bar>
  );
};

export default DeliverBar;
