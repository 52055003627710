import {
  LOADING,
  GET_ITEMS,
  SET_DEFAULT_ITEMS,
  SET_ITEM
} from "../actions/types";

const defaultItems = [
  {
    type: "single_item_vertical",
    sectionHeader: "Deal of the day",
    items: [
      {
        title: "product6",
        description: "Tzowla College School Travel Casual Daypack Backpack purse for women Book Doctor Shopping Mini Bag Light Weight for Men Girls Boys Student Fit 14 inch Computer Netbook-Black",
        price: "26",
        image: "/images/products/6.jpg"
      },
      {
        title: "product1",
        description: "Targus Legend IQ Backpack Laptop bag for Business Professional and College Student with Durable Material, Pockets Throughout, Headphone Cord Pocket, TrolleyStrap, Fits 16-Inch Laptop, Black (TSB705US)",
        price: "16.25",
        image: "/images/products/5.jpg"
      },
      {
        title: "product1",
        description: "Amazon Essentials Men's Nolte Loafer",
        price: "34.25",
        image: "/images/products/4.jpg"
      },
      {
        title: "product1",
        description: "Owala FreeSip Insulated Stainless Steel Water Bottle with Straw for Sports and Travel, BPA-Free, 24-Ounce, Very, Very Dark",
        price: "12.25",
        image: "/images/products/3.jpg"
      }
    ],
    sectionFooter: "Top Deals"
  },
  {
    type: "default",
    sectionHeader: "Books",
    items: [
      {
        title: "book1",
        description: "One Last StopOne",
        price: "05.25",
        image: "/images/products/31.jpg"
      },
      {
        title: "book1",
        description: "The Last Thing He Told Me: A Novel Hardcover – May 4, 2021",
        price: "18.25",
        image: "/images/products/32.jpg"
      },
      {
        title: "book1",
        description: "Junie B. Jones's First Boxed Set Ever! (Books 1-4)",
        price: "10.25",
        image: "/images/products/33.jpg"
      },
      {
        title: "book1",
        description: "Oh, the Places You'll Go!   Hardcover – January 22, 1990",
        price: "25.25",
        image: "/images/products/34.jpg"
      },
      {
        title: "book1",
        description: "It Ends with Us: A Novel Paperback – August 2, 2016",
        price: "13.13",
        image: "/images/products/35.jpg"
      }
    ],
    sectionFooter: "Test footer"
  },
  {
    type: "grid_4_items_image_only",
    sectionHeader: "Shop by style",
    items: [
      {
        title: "Sporty",
        description: "lorem ipsum about description",
        price: "15.25",
        image: "/images/products/11.jpg"
      },
      {
        title: "Cool",
        description: "lorem ipsum about description",
        price: "55.25",
        image: "/images/products/12.jpg"
      },
      {
        title: "Boho",
        description: "lorem ipsum about description",
        price: "15.25",
        image: "/images/products/13.jpg"
      },
      {
        title: "Minimal",
        description: "lorem ipsum about description",
        price: "55.25",
        image: "/images/products/14.jpg"
      }
    ],
    sectionFooter: "Top Deals"
  },

  {
    sectionHeader: "Amazon Top Sellers",
    items: [
      {
        title: "product3",
        description: "Stanley Classic Vacuum Insulated Wide Mouth Bottle",
        price: "15.25",
        image: "/images/products/3.jpg"
      },
      {
        title: "product2",
        description: "Amazon Basics Fiberglass Handle Claw Hammer - 20 oz.",
        price: "30.25",
        image: "/images/products/2.jpg"
      },
      {
        title: "product2",
        description: "Echo Show 5 (1st Gen, 2019 release)",
        price: "30.25",
        image: "/images/products/1.jpg"
      },
      {
        title: "product2",
        description: "lorem ipsum about description",
        price: "30.25",
        image: "/images/products/25.jpg"
      },
      {
        title: "product4",
        description: "lorem ipsum about description",
        price: "55.25",
        image: "/images/products/4.jpg"
      }
    ],
    sectionFooter: "Top Sellers"
  },
  {
    type: "grid_4_items",
    sectionHeader: "Shop by style",
    items: [
      {
        title: "Sporty",
        description: "lorem ipsum about description",
        price: "15.25",
        image: "/images/products/7.jpg"
      },
      {
        title: "Cool",
        description: "lorem ipsum about description",
        price: "55.25",
        image: "/images/products/8.jpg"
      },
      {
        title: "Boho",
        description: "lorem ipsum about description",
        price: "15.25",
        image: "/images/products/9.jpg"
      },
      {
        title: "Minimal",
        description: "lorem ipsum about description",
        price: "55.25",
        image: "/images/products/10.jpg"
      }
    ],
    sectionFooter: "Test footer"
  },
  {
    type: "grid_1_item",
    sectionHeader: "Men's fashion finds",
    items: [
      {
        title: "Sporty",
        description: "lorem ipsum about description",
        price: "15.25",
        image: "/images/products/15.jpg"
      },
      {
        title: "Sporty",
        description: "lorem ipsum about description",
        price: "35.00",
        image: "/images/products/40.jpg"
      },
      {
        title: "Sporty",
        description: "lorem ipsum about description",
        price: "11.29",
        image: "/images/products/41.jpg"
      },
      {
        title: "Sporty",
        description: "lorem ipsum about description",
        price: "10.25",
        image: "/images/products/42.jpg"
      },
      {
        title: "Sporty",
        description: "lorem ipsum about description",
        price: "19.12",
        image: "/images/products/43.jpg"
      },
      {
        title: "Sporty",
        description: "lorem ipsum about description",
        price: "22.45",
        image: "/images/products/44.jpg"
      },
      {
        title: "Sporty",
        description: "lorem ipsum about description",
        price: "15.25",
        image: "/images/products/45.jpg"
      }
    ],
    sectionFooter: "Test footer"
  },
  {
    sectionHeader: "Trending Healthy Products",
    items: [
      {
        title: "product3",
        description: "Vade Nutrition Dissolvable Protein Packs | Vanilla Whey Isolate Protein Powder",
        price: "15.25",
        image: "/images/products/25.jpg"
      },
      {
        title: "product4",
        description: "AGN Roots Grassfed Whey Protein | Certified Brand List ASPCA | Certified Entire Life On Pasture Grass Fed",
        price: "55.25",
        image: "/images/products/26.jpg"
      },
      {
        title: "product3",
        description: "Optimum Nutrition Gold Standard 100% Whey Protein Powder",
        price: "15.25",
        image: "/images/products/27.jpg"
      },
      {
        title: "product4",
        description: "lorem ipsum about description",
        price: "55.25",
        image: "/images/products/28.jpg"
      },
      {
        title: "product3",
        description: "Optimum Nutrition Gold Standard 100% Whey Protein Vanilla Flavor 5.46 lb",
        price: "156.25",
        image: "/images/products/29.jpg"
      }
    ],
    sectionFooter: "Test footer"
  },
  {
    type: "grid_9_items",
    sectionHeader: "Shop health and wellness",
    items: [
      {
        title: "item1",
        description: "lorem ipsum about description",
        price: "15.25",
        image: "/images/products/16.jpg"
      },
      {
        title: "product4",
        description: "lorem ipsum about description",
        price: "55.25",
        image: "/images/products/17.jpg"
      },
      {
        title: "item1",
        description: "lorem ipsum about description",
        price: "15.25",
        image: "/images/products/18.jpg"
      },
      {
        title: "product4",
        description: "lorem ipsum about description",
        price: "55.25",
        image: "/images/products/19.jpg"
      },
      {
        title: "item1",
        description: "lorem ipsum about description",
        price: "15.25",
        image: "/images/products/20.jpg"
      },
      {
        title: "product4",
        description: "lorem ipsum about description",
        price: "55.25",
        image: "/images/products/21.jpg"
      },
      {
        title: "item1",
        description: "lorem ipsum about description",
        price: "15.25",
        image: "/images/products/22.jpg"
      },
      {
        title: "product4",
        description: "lorem ipsum about description",
        price: "55.25",
        image: "/images/products/23.jpg"
      },
      {
        title: "product4",
        description: "lorem ipsum about description",
        price: "55.25",
        image: "/images/products/24.jpg"
      }
    ],
    sectionFooter: "Test footer"
  }
];
const initialState = {
  items: defaultItems,
  item: {},
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      };

    case GET_ITEMS:
      let sections = [];
      // :::TODO:::
      return {
        ...state,
        loading: false
      };

    case SET_ITEM:
      return {
        ...state,
        item: action.payload
      };

    case SET_DEFAULT_ITEMS:
      return {
        ...state,
        items: defaultItems
      };

    default:
      return { ...state };
  }
};
