import React from "react";

const SectionHeader = ({ header }) => {
  return (
    <div className="body-section-header">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "42px"
        }}
      >
        <h3>{header}</h3>
      </div>
      <hr />
    </div>
  );
};

export default SectionHeader;
