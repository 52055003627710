import { RESET_CART, SET_CART } from "./types";

export const resetCart = () => dispatch => {
  return dispatch({
    type: RESET_CART
  });
};

export const setCart = num => dispatch => {
  console.log(num);
  return dispatch({
    type: SET_CART,
    payload: num
  });
};
