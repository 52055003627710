import React, { useState, useEffect } from "react";
import ItemView from "./ItemView";
import CartView from "./CartView";
import PlaceOrder from "./PlaceOrder";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Index = () => {
  const history = useHistory();
  const [item, setItem] = useState(null);
  const [view, setView] = useState("singleItem");
  const { items } = useSelector((state) => state);
  useEffect(() => {
    if (Object.keys(items.item).length == 0) {
      history.push("/");
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [view]);
  useEffect(() => {
    if (!items.item || Object.keys(items.item).length == 0) {
    } else {
      let product = item;
      product = { ...items.item };
      setItem(product);
    }
  }, []);

  const onCtaClickHandler = (event) => {
    let show = view;
    show = event.target.innerText.toLowerCase();
    setView(show);
  };

  return (
    <div>
      <div className="item-view">
        {view == "singleItem" && (
          <ItemView item={items.item} onCtaClick={onCtaClickHandler} />
        )}

        {view == "add to cart" && (
          <div>
            <div>
              <span
                style={{ textDecoration: "underline", marginRight: "10px" }}
              >
                Cart
              </span>
              Whole food code
            </div>
            <CartView item={items.item} onCtaClick={onCtaClickHandler} />
          </div>
        )}

        {view == "proceed to checkout" && (
          <div>
            <small>
              By placing your order, you agree to Amazon.com's{" "}
              <span className="blue">privecy notice</span> and{" "}
              <span className="blue">conditions of user</span>
            </small>
            <PlaceOrder item={items.item} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
