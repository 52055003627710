import React from "react";
import { type } from "os";

const GridImageCard = ({ item, hiddenAfter, index, titles = false }) => {
  let containerFlexStyles = {};
  if (titles == false) {
    containerFlexStyles = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    };
  }
  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        textAlign: "center",
        ...containerFlexStyles
      }}
    >
      <img
        src={item.image}
        alt={item.title}
        style={{
          maxWidth: "100%",
          maxHeight: "100%"
        }}
      />
      {titles ? <h4 style={{ textAlign: "left" }}>{item.title}</h4> : null}

      {titles == false && (
        <div
          style={{
            background: "#000",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            opacity: "0.3"
          }}
        />
      )}
    </div>
  );
};

export default GridImageCard;
