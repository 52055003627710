import React, { Component } from "react";
import TopOfPage from "./TopOfPage";
import NavWrapper from "./NavWrapper";
import Tail from "./Tail";

export default class index extends Component {
  render() {
    return (
      <footer>
        <TopOfPage />
        <NavWrapper />
        <Tail />
      </footer>
    );
  }
}
