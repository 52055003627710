import React from "react";
import Section from "./Section";

const Tail = () => {
  return (
    <section
      style={{
        background: "#0e141e",
        textAlign: "center"
      }}
    >
      <Section
        style={{
          justifyContent: "center",
          paddingTop: "5px"
        }}
      >
        <div className="main_container">
        <div className="left">
          <div>
            <img src="/images/lang.png" alt="English" width="15" />
          </div>
          <div><p>English</p></div>
        </div>
        <div className="center">
          <div>
            <img src="/images/dollar-sign.svg" alt="dollar" width="20" />
          </div>
         <div> <p>USD - U.S. Dollar</p></div>
        </div>
        <div className="right">
          <div>
            <img src="/images/us.png" alt="US" width="20" />
          </div>
          <div>
          <p>United States</p>

          </div>
          </div>
        </div>
      </Section>
<div  style={{
  display:"flex",
  justifyContent:"center",
  
          paddingTop: "20px",
          marginBottom:"18px",
        }}>
      <div
        style={{
          paddingRight: "4px",
          fontSize:"12px"
        }}
      >
        <h3>Already a customer? </h3> 
      </div>

      <div
        style={{
          fontSize:"12px"
          
        }}
      >
        <h3>  Sign In</h3>
      </div>
      </div>

      <Section
        style={{
          justifyContent: "center",
          marginBottom:"-1rem"
        }}
      >
        <ul className="inlined-list">
          <li>
            <small>Conditions of Use</small>
          </li>
          <li>
            <small>Privacy Notice</small>
          </li>
          <li>
            <small>Interest-Based Ads</small>
          </li>
        </ul>
      </Section>
      <Section
        style={{
          justifyContent: "center",
          paddingBottom: "15px"
        }}
      >
        <div className="footer_bottom">
          <small>&copy; 1996-2019, Amazon.com, Inc. or its affiliates</small>
        </div>
      </Section>
    </section>
  );
};

export default Tail;
