import React from "react";

const CartView = ({ item, onCtaClick }) => {
  const {
    price,
    stars,
    image_src,
    prime_detail,
    title,
    detail,
    highlights,
    prime,
    web_scraper_start_url,
    ratings,
  } = item;

  return (
    <div className="item-wrapper">
      <div className="proceed">
        <div>
          <strong>Cart subtotal</strong> (1 item):$
          <span className="red">{price}</span>
        </div>

        <div className="row">
          <div className="input">
            <input type="checkbox" />
          </div>
          <div className="purchace">
            <p>This order contains a gift</p>
          </div>
        </div>

        <div className="cta-holder">
          <button className="cta" onClick={onCtaClick}>
            Proceed to checkout
          </button>
        </div>

        <hr />

        <div className="card">
          <div className="image-holder">
            <img src={image_src} alt="" />
          </div>
          <div className="data">
            <h4 className="description">{title}</h4>
            <div className="row">
              <div className="price">
                <span className="red">${price}</span>
              </div>
              {prime === "Amazon Prime" ? (
              <div className="prime-image-holder">
                <img src="/images/prime.png" alt="prime" />
              </div>
            ) : (
              ""
            )}
            </div>
            <div className="green">In Stock</div>
            <div className="message blue">Save more with Subscripe & Save</div>
          </div>
        </div>
        <div className="buttons-holder">
          <select name="" id="">
            <option value="1">1</option>
          </select>
          <button>Delete</button>
          <button>Save for later</button>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default CartView;
