import React from "react";
import { useSelector } from "react-redux";
import speakerImg from '../../Assets/caroselImg.jpg';

const ProductCarousel = () => {
const {count} = useSelector(state => state.cart)

  return (
    <div className="carousel_main_container">
      {/* <div className="left_carousel">
        <h2>Welcome</h2>
        <p>Sign in for the best experience</p>
        <button>Sign in</button>
        <br />
        <a>Create an account</a>
      </div> */}
      <div className="right_carousel">
        <div>
          {/* <img src="	https://m.media-amazon.com/images/I/413tmL4Y0kL._SR270,360_.jpg" /> */}
          <img src={speakerImg} />
        <p>Supersonic Bold Speakers. <br/> <span>{`$14.${count}`} </span></p>

        </div>
      </div>
      <div className="right_carousel">
        <div>
          <img src="https://m.media-amazon.com/images/I/51BwEB9glsL._SR270,360_.png" />
        </div>
        {/* <p>{`17.${count}`}</p> */}
        
      </div>
      <div className="right_carousel">
        <div>
          <img src="https://m.media-amazon.com/images/I/31ZZ6Gc+4wL._SR270,360_.jpg" />
        </div>
        {/* <p>{`11.${count}`}</p> */}
      </div>
    </div>
  );
};

export default ProductCarousel;
