import React, { Component } from "react";
import { connect } from "react-redux";
import { setCart } from "../../actions/cart";

class NumGrid extends Component {
  state = {
    grids: [1, 2, 3, 4, 5, 6, 7, 8, 9, null, 0, null],
    top: 184,
    cartCount: 0
  };

  componentDidMount() {
    let top = document.querySelector("header").clientHeight;
    let cartCount = this.props.cart.cartCount || 0;
    this.setState({ top, cartCount });
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.heandleScroll);
  }

  heandleScroll = () => {
    let headerHeight = document.querySelector("header").clientHeight;
    let top =
      window.scrollY >= headerHeight ? 0 : headerHeight - window.scrollY;
    if (top !== this.state.top) {
      this.setState({ top });
    }
  };

  updateCartCount = event => {
    let cartCount = this.state.cartCount;
    let newDigit = event.target.innerText;
    if (!newDigit) return;

    let numStr = String(cartCount) + newDigit;
    numStr = numStr.slice(numStr.length - 2);
    cartCount = parseInt(numStr);

    this.setState({ cartCount }, () => {
      this.props.setCart(cartCount);
    });
  };

  render() {
    const { grids } = this.state;

    /* GETS THE START TOP POSITION FOR THE INVISIBLE NUM GRID */

    document.addEventListener("scroll", this.heandleScroll);

    return (
      <div className="numGrid" style={{ top: this.state.top }}>
        {grids.map((item, i) => (
          <div className="num-grid-item" key={i} onClick={this.updateCartCount}>
            {item}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cart: state.cart
});

const mapDispatchToProps = dispatch => ({
  setCart: num => dispatch(setCart(num))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumGrid);
