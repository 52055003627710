import { RESET_CART, SET_CART } from "../actions/types";

const initialState = {
  count: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_CART:
      return {
        count: 0
      };

    case SET_CART:
      return {
        ...state,
        count: action.payload
      };
    default:
      return {
        ...state
      };
  }
};
