import React, { Component } from "react";
import { connect } from "react-redux";
import Section from "../ui/Section/Section";
import NumGrid from "../ui/NumGrid";
import { getItems } from "../../actions/Items";
import ProductCarousel from "./product_carousel";

class Body extends Component {
  state = {
    sections: [],
  };

  componentDidMount = () => {
    const sections = this.props.items.items;
    this.setState({ sections });
  };

  generateGiftCardAd = () => {
    return (
      <iframe
        src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=gift_certificates&banner=127JF9E4530CSFRCY4R2&f=ifr&linkID=637c288d466cfbf134281636c922fca0&t=amazonn0a-20&tracking_id=amazonn0a-20"
        width="300"
        height="250"
        scrolling="no"
        border="0"
        marginWidth="0"
        style={{ border: "none" }}
        frameBorder="0"
      />
    );
  };

  // generateMobileAd = () => (
  //   <iframe
  //     src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=amzn_firetv_tradein&banner=19P8ESBFAJ7SQV9DE2G2&f=ifr&lc=pf4&linkID=c0a5237a28695cc9555191b92e914928&t=amazonn0a-20&tracking_id=amazonn0a-20"
  //     width="300"
  //     height="250"
  //     scrolling="no"
  //     border="0"
  //     marginWidth="0"
  //     style={{ border: "none" }}
  //     frameBorder="0"
  //   />
  // );

  render() {
    const { sections } = this.state;

    return (
      <main className="main-content">
        <NumGrid />
        <div className="mobile-only ad">
          <img
            src={
              "https://m.media-amazon.com/images/I/51CWHEaL4vL._SR1236,1080_.jpg"
            }
          />
          {/* {this.generateMobileAd()} */}
        </div>
        <ProductCarousel />
        {sections.map((section, i) =>
          i == sections.length - 2 ? (
            <div className="with-ad" key={i}>
              <Section section={section} />
              <div className="dt-only">{this.generateGiftCardAd()}</div>
            </div>
          ) : (
            <Section section={section} key={i} />
          )
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  items: state.items,
});
const mapDispatchToProps = (dispatch) => ({
  getItems: () => dispatch(getItems()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Body);
