import React from "react";

const itemView = ({ item, onCtaClick }) => {
  const {
    price,
    stars,
    image_src,
    prime_detail,
    title,
    detail,
    highlights,
    prime,
    web_scraper_start_url,
    ratings,
  } = item;
  const generateRatingStars = () => {
    let star = "";
    let r = parseInt(stars);
    let operations = 0;
    while (r > 0) {
      if (r > 0 && r < 1) {
        star += "<i class='fas fa-star-half-alt'/>";
      } else {
        star += "<i class='fas fa-star'></i>";
      }
      r--;
      operations++;
    }
    while (operations < 5) {
      star += "<i class='far fa-star'></i>";
      operations++;
    }
    return star;
  };

  return (
    <div className="item-wrapper">
      <div className="top">
        <p className="sold-by">{prime}</p>
        <div className="rating-holder">
          <div
            className="rating"
            dangerouslySetInnerHTML={{ __html: generateRatingStars() }}
          />
          <div className="feedback">{ratings}</div>
        </div>
      </div>
      <h4 className="description">{title}</h4>
      <div className="image-holder">
        <img src={image_src} alt="" />
      </div>
      <div className="data">
        <div className="price">${price}</div>
        {prime === "Amazon Prime" ? (
              <div className="prime-image-holder">
                <img src="/images/prime.png" alt="prime" />
              </div>
            ) : (
              ""
            )}
        <div className="message" />
        <div className="delivery">
            <div className="days">
              {detail === null ? "" : detail} on qualifying orders over $35
            </div>
        </div>
      </div>

      <div className="purchace-options">
        <div className="row">
          <div className="radio">
            <input type="radio" checked />
          </div>
          <div className="purchace">
            One Time purchace:
            <span className="red">${price}</span>
          </div>
        </div>
        <div className="green">In Stock</div>
        <p>Ships from and sold by Amazon.com, Gift wrap avilable.</p>

        <div className="row">
          <div className="pin-image-container">
            <img
              src="/images/pin-dark.png"
              width="20"
              height="22"
              alt="Deliver"
              style={{ marginRight: "10px" }}
            />
          </div>
          <div>
            <p className="blue">Deliver to Trigg - Dallas 75226</p>
          </div>
        </div>
        <select name="" id="">
          <option value="1">Qty 1</option>
        </select>
        <div className="cta-holder">
          <button className="cta" onClick={onCtaClick}>
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default itemView;
