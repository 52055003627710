import React from "react";
import SingleItemVerticalCard from "./SingleItemVerticalCard";
import SingleItemHorizontalCard from "./SingleItemHorizontalCard";
import GridImageCard from "./GridImageCard";

const Card = ({ type, items }) => {
  const generateTemplate = (item, i) => {
    let template;

    switch (type) {
      case "grid_4_items_image_only":
        return <GridImageCard item={item} titles={false} />;

      case "grid_4_items":
        return <GridImageCard item={item} titles={true} />;

      case "grid_1_item":
        return (
          <SingleItemVerticalCard
            item={item}
            hiddenAfter={1}
            index={i}
            titles={false}
          />
        );

      case "grid_9_items":
        return <GridImageCard item={item} titles={false} />;

      case "single_item_vertical":
        return <SingleItemVerticalCard item={item} hiddenAfter={1} index={i} />;

      default:
        template = (
          <SingleItemHorizontalCard item={item} hiddenAfter={3} index={i} />
        );
        return template;
    }
  };

  let classes =
    type == "grid_4_items_image_only" ||
    type == "grid_4_items" ||
    type == "grid_9_items"
      ? "body-section-content-flex"
      : "body-section-content";

  return (
    <div className={classes}>
      {items.map((item, i) => (
        <div
          className={type == "grid_9_items" ? "card-item grid_9" : "card-item"}
          key={i}
        >
          {generateTemplate(item, i)}
        </div>
      ))}
    </div>
  );
};

export default Card;
