import React from "react";

const SectionFooter = ({ content }) => {
  let text = content ? content : "See more";

  return (
    <div className="body-section-footer">
      <hr />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "42px"
        }}
      >
        <a href="">See more</a>
        <i className="fas fa-angle-right" />
      </div>
    </div>
  );
};

export default SectionFooter;
