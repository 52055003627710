import React from "react";
import Bar from "./Bar";

const Navigation = () => {
  return (
    <Bar>
      <ul className="navigation-menu">
        <li>Best Sellers</li>
        <li>Video</li>
        <li>Live Streams</li>
        <li>New Releases</li>
      </ul>
    </Bar>
  );
};

export default Navigation;
