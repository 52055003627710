import React from "react";
import Bar from "../Header/Bar";

const TopBar = ({ results }) => {
  return (
    <Bar>
      <div className="results-count">{results} results found</div>
     
      <div className="filter">
     <div><h2>Filters</h2> </div>  
      <div> <i className=" arrow fas fa-angle-down" /></div> 
      </div>
    </Bar>
  );
};

export default TopBar;
