import React, { useEffect } from "react";
import { setItem } from "../../actions/Items";
import { history } from "../../routers/AppRouter";
import { useDispatch } from "react-redux";

const Item = ({ item }) => {
  const dispatch = useDispatch();
  const {
    price,
    stars,
    image_src,
    prime_detail,
    title,
    detail,
    highlights,
    prime,
    web_scraper_start_url,
    ratings,
  } = item;
  const generateRatingStars = () => {
    let r = parseFloat(stars);
    let star = "";
    // Get the number of whole stars
    var iWholeStars = Math.floor(r);
    // Do we want a half star?
    var blnHalfStar = Math.round(r * 10) / 10 - iWholeStars;

    let operations = 0;
    while (r > 0) {
      if (iWholeStars > 0) {
        star += "<i class='fas fa-star'></i>";
      } else {
        star += "<i class='fas fa-star-half-alt'/>";
        blnHalfStar = 0;
      }
      r--;
      operations++;
      iWholeStars--;
    }
    while (operations < 5) {
      star += "<i class='far fa-star'></i>";
      operations++;
    }

    return star;
  };

  const handleSetItem = () => {
    dispatch(setItem(item));
    window.location.href = web_scraper_start_url;
    // history.push("/item");
  };
  const p = price.split(".");
  return (
    <div className="main_div">
      <div className="item-wrapper" onClick={handleSetItem}>
        <div className="image-holder">
          <img src={image_src} alt="" />
        </div>
        <div className="data">
          <h2 className="description">{`${title}...`}</h2>
          {/* <p className="sold-by">{prime}</p> */}
          <div className="rating-holder">
            <div
              className="rating"
              dangerouslySetInnerHTML={{ __html: generateRatingStars() }}
            ></div>
            <div className="feedback">{ratings}</div>
          </div>
          <div className="price">
            <sup className="dollar">$</sup>
            {p[0]}
            <sup className="cent">{p[1]}</sup>
          </div>
          {prime?.length ? (
            <div className="prime-image-holder">
              <img src="/images/prime.png" alt="prime" />
            </div>
          ) : (
            ""
          )}
          <div className="ship_location">
            <p>{prime_detail}</p>
          </div>

          <div className="ship_location">
            <p>{detail === null ? "" : detail}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
