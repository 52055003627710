import React from "react";
import { useSelector } from "react-redux";
const SingleItemVerticalCard = ({
  item,
  hiddenAfter,
  index,
  titles = true
}) => {
  const dtOnly = hiddenAfter && index && hiddenAfter <= index;
const {count} = useSelector(state => state.cart)
  return (
    <div
      className={dtOnly ? "dt-only" : ""}
      style={{
        paddingBottom: "10px"
      }}
    >
      <div
        className="image-holder"
        style={{ textAlign: "center", margin: "10px 10px 15px" }}
      >
        <img src={item.image} height="200" alt={item.title} />
      </div>
      {titles && <h3>{item.description}</h3>}
      <h3>
        <sup>$</sup>
        {`${item.price}.${count}`}
      </h3>
    </div>
  );
};

export default SingleItemVerticalCard;
