import React,{useEffect} from "react";
import { Provider } from "react-redux";
import AppRouter, { history } from "./routers/AppRouter";
import configureStore from "./store/configureStore";
import "normalize.css/normalize.css";
import "./styles/styles.scss";

const store = configureStore();

function App() {
  const survey=localStorage.getItem("survey")
  useEffect(() => {
   if(survey=="completed"){
    window.location.replace("https://www.amazon.com/");

   }
  }, [])
  console.log(survey,"sur")
  return (
    <div className="App">
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </div>
  );
}

export default App;
