import React, { useState, useEffect } from "react";
import axios from "axios";
import TopBar from "./TopBar";
import Item from "./Item";
import { useSelector } from "react-redux";

const SearchPage = () => {
  const [items, setItems] = useState([]);
  const [presetProduct, setPresetProduct] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [error, setError] = useState(null);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(true);
  const [previous, setPrevious] = useState(0);
  const [next, setNext] = useState(17);
  const { cart } = useSelector((state) => state);

  const getAllProducts = () => {
    return axios
      .get("https://amazonn.com.co/products/getAll")
      .then((res) => {
        setItems(res.data.data);
      })
      .catch((err) => setError(err.response));
  };
  const getPresetProduct = () => {
    return axios
      .get("https://amazonn.com.co/presentProducts/getSelectedProduct")
      .then((res) => {
        setItems(null);

        let arr = [...items];
        arr.splice(cart?.count - 1, 0, res.data.data[0]);
        if (arr) {
          setPresetProduct(arr);
          setDataToShow(arr.slice(previous, next));
        }
      })
      .catch((err) => setError(err.response));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dataToShow]);
  const nextProduct = (i) => {
    if (presetProduct.length > next + i) {
      setDataToShow(null);
      setDataToShow(presetProduct.slice(previous + i, next + i));
      setNext(next + i);
      setPrevious(previous + i);
      setDisablePrevious(false);
    } else {
      if (next === presetProduct.length) return;
      setDisableNext(true);
      setDataToShow(null);
      setNext(presetProduct.length);
      setPrevious(previous + i);
      setDataToShow(presetProduct.slice(next, presetProduct.length));
    }
  };
  const previousProduct = (i) => {
    if (presetProduct.length === next) {
      let middleWare = next - previous;
      setDataToShow(null);
      setNext(next - middleWare);
      setPrevious(previous - i);
      setDataToShow(presetProduct.slice(previous - i, next - middleWare));
      setDisableNext(false);
    } else {
      if (previous === 0) return;
      if (previous === i) {
        setDisablePrevious(true);
      }
      setDisableNext(false);
      setDataToShow(null);
      setDataToShow(presetProduct.slice(previous - i, next - i));
      setPrevious(previous - i);
      setNext(next - i);
    }
  };
  useEffect(() => {
    getAllProducts();
  }, []);
  useEffect(() => {
    getPresetProduct();
  }, [items]);
  return (
    <div className="search-page">
      <TopBar results={presetProduct?.length} />

      <div className="content">
        <div className="results_heading">
          <h2>RESULTS</h2>
          <p>
            Price and other details may vary based on product size and color.
          </p>
        </div>
        {error && <div className="error">{error}</div>}
        {dataToShow && dataToShow.length >1 ? (
          dataToShow?.map((item, i) => <Item item={item ? item : {}} key={i} />)
        ) : null}
      </div>
      <div className="buttons_container">
        <button
          className={disablePrevious ? `disabled_button` : `previous_button`}
          onClick={(e) => previousProduct(17)}
        >
          ← Previous
        </button>
        <button
          className={disableNext ? `disabled_button` : `next_button`}
          onClick={(e) => nextProduct(17)}
        >
          Next →
        </button>
      </div>
    </div>
  );
};

export default SearchPage;
