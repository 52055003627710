import React from "react";
import NavItem from "./NavItem";

const NavList = ({ list }) => {
  return (
    <ul className="nav-list">
      {list.map((item, i) => (
        <NavItem item={item} key={i} />
      ))}
    </ul>
  );
};

export default NavList;
