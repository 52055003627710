import React, { Component } from "react";
import { history } from "../../routers/AppRouter";
import Bar from "./Bar";

export default class SearchBar extends Component {
  state = {
    formData: {
      search: {
        name: "search",
        value: "",
        placehoder: "Search Amazon"
      }
    }
  };

  onChangeHandler = event => {
    const { value, name } = event.target;
    const formData = { ...this.state.formData };
    formData[name].value = value;
    this.setState({ formData });
  };

  onSearchSubmit = event => {
    event.preventDefault();
    const { value } = this.state.formData.search;

    if (value.trim().length) {
      history.push(`/search/${value.trim()}`);
    }
  };

  render() {
    let onMobile = this.props.mobile;
    const { search } = this.state.formData;
    const { value, placehoder, name } = search;

    return (
      <Bar>
        <form>
          {!onMobile && (
            <select>
              <option value="default">All</option>
            </select>
          )}
          <input
            type="text"
            name={name}
            onChange={this.onChangeHandler}
            value={value}
            placeholder={placehoder}
          />
          <button onClick={this.onSearchSubmit}>
            <img src="/images/search.png" width="22" alt="Search" />
          </button>
        </form>
      </Bar>
    );
  }
}
