import React, { Component } from "react";

export default class Section extends Component {
  render() {
    let classes = this.props.classes
      ? this.props.classes + " section"
      : "section";
    return (
      <div style={{ ...this.props.style }}>
        <div
          className={classes}
          style={{ maxWidth: "1500px", margin: "0 auto", ...this.props.style }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
